<template>
  <div class="timeline-container">
    <div class="col-12 ml-md-5 pl-0 pl-md-5 pt-4 timeline-add-container">
      <router-link
        :to="{ name: 'tasks.add', query: { taskType: 'Note' } }"
        class="btn btn-outline-blue mb-3 btn-sm"
      >
        <icon type="plus" /> Add Note
      </router-link>

      <router-link
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
        :to="{ name: 'Tasks' }"
        class="btn btn-secondary mb-3 ml-3"
      >
        <icon type="envelope fa-lg" /> Inbox
      </router-link>
    </div>
    <div class="pl-md-5 pr-md-5 ml-md-5 mr-md-5 mb-5 timeline-table-container">
      <timeline
        :userId="user.id"
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "timeline-provider",
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  data() {
    return {};
  },
};
</script>
